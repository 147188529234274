import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import AdminStaffTable from "../../Items/Table/admin/AdminStaffTable";
const ViewMyStaff = () => {
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [StaffList, setStaffList] = useState<StaffProp[]>([]);

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getCompanyStaffForAdmin");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      setStaffList(data || []);
    }
  }, [apiResponse]);
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        <Col span={24}>
          <AdminStaffTable staff={StaffList} isLoading={apiLoading} />
        </Col>
      </Row>
    </>
  );
};
export default ViewMyStaff;
