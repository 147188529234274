import React from "react";
import { Modal, ModalProps } from "antd";
import { ReactNode } from "react";
import { ReactComponent as DefaultModalCloseIcon } from "./../../../assets/svgs/DefaultModalCloseIcon.svg";
interface MikroMatikModalProps extends ModalProps {
  headerStyle?: React.CSSProperties;
  closeIcon?: ReactNode;
  children?: ReactNode;
  title: string;
  titleImage?: string;
  minWidthPercentage?: string;
}
const MikroMatikModal: React.FC<MikroMatikModalProps> = ({
  headerStyle,
  closeIcon,
  children,
  title,
  titleImage,
  minWidthPercentage,
  ...rest
}) => {
  const titleContent = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...headerStyle,
      }}
    >
      {titleImage && (
        <img src={titleImage} alt="Title" style={{ marginRight: "10px" }} />
      )}
      {title}
    </div>
  );
  return (
    <Modal
      {...rest}
      wrapClassName="mikromatik-modal"
      closeIcon={closeIcon || <DefaultModalCloseIcon />}
      title={titleContent}
      footer={null}
      keyboard={false}
      maskClosable={false}
      style={minWidthPercentage ? { minWidth: minWidthPercentage } : {}}
    >
      {children}
    </Modal>
  );
};
export default MikroMatikModal;
