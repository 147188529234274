import React from "react";
import { Button } from "antd";

interface ClickParam {
  [key: string]: any;
}

interface MikroMatikButtonProp {
  borderColor?: string;
  backgroundColor?: string;
  height?: string;
  width?: string;
  textColor?: string;
  onClick: (param?: ClickParam) => void;
  text: string;
  fontSize?: string;
  fontWeight?: string;
  icon?: React.ReactNode;
  htmlType?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}

const MikroMatikButton: React.FC<MikroMatikButtonProp> = ({
  backgroundColor = "linear-gradient(276.9deg, #67318C 5.85%, #1C1997 93.97%)",
  height = "40px",
  width = "100px",
  textColor = "white",
  onClick,
  text,
  borderColor = "#67318C",
  fontSize = "16px",
  fontWeight = "400",
  icon = null,
  htmlType = "button",
  disabled = false,
}) => {
  const buttonStyle: React.CSSProperties = {
    background: backgroundColor,
    color: textColor,
    height,
    width,
    borderColor,
    fontSize,
    fontWeight,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  };
  const iconStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };

  return (
    <Button
      disabled={disabled}
      htmlType={htmlType}
      onClick={onClick}
      style={buttonStyle}
    >
      {icon && <span style={iconStyle}>{icon}</span>}
      {text}
    </Button>
  );
};

export default MikroMatikButton;
