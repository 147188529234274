import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./index.css";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import CompaniesTable from "../Items/Table/CompaniesTable";
import { ButtonProps } from "../../helpers/constants";
import MikroMatikButton from "../Items/Button/Index";
const DashboardComponent = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();
  const [companies, setCompanies] = useState<CompanyProp[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getAllCompanies");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      setCompanies(data || []);
    }
  }, [apiResponse]);
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        <Col span={6} offset={18}>
          <MikroMatikButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              navigate(`/create-company`);
            }}
            text="Create Company"
          />
        </Col>
        <Col span={24}>
          <CompaniesTable companies={companies} isLoading={apiLoading} />
        </Col>
      </Row>
    </>
  );
};
export default DashboardComponent;
