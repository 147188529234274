import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDate } from "../../../helpers/date";

import { MoreOutlined } from "@ant-design/icons";
import "./index.css";
import { getUnitFromKMG } from "../../../helpers/strings";
const ChildPoliciesTable: React.FC<ChildPoliciesTableProp> = ({
  polices,
  isLoading,
  handleEditChild,
  handleDeleteChild,
}) => {
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState<ChildPolicyProp | null>(null);
  const childPolicyItemOptions: MenuProps["items"] = [
    {
      key: "EDIT",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={KeyIcon} /> */}
          Edit Policy
        </Typography>
      ),
    },
    {
      key: "DELETE",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={KeyIcon} /> */}
          Delete Policy
        </Typography>
      ),
    },
  ];

  const columns: TableProps<ChildPolicyProp>["columns"] = [
    {
      title: "Name",
      dataIndex: "childQueProfileDisplayName",
      key: "childQueProfileDisplayName",
    },
    {
      title: "Time",
      dataIndex: "child_Time_String",
      key: "child_Time_String",
    },
    {
      title: "Speed",
      render: (row) => (
        <span>
          {row.child_MaxLimit_UploadInt ? row.child_MaxLimit_UploadInt : ""}/
          {row.child_MaxLimit_DownloadInt ? row.child_MaxLimit_DownloadInt : ""}
          {row.child_MaxLimit_AbbrvStr
            ? getUnitFromKMG(row.child_MaxLimit_AbbrvStr)
            : ""}
        </span>
      ),
    },

    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: childPolicyItemOptions,

            onClick: handleMenuClick,
          }}
          placement="bottomLeft"
        >
          <MoreOutlined
            onMouseOver={(e) => {
              e.preventDefault();
              setActiveRow(row);
            }}
          />
        </Dropdown>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    if (e.key === "EDIT") {
      handleEditChild(activeRow?.id || 0);
    }
    if (e.key === "DELETE") {
      handleDeleteChild(activeRow?.id || 0);
    }
  };
  return (
    <div className="mikromatik-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="mikromatik-user-tables-heading">
                Child Profiles
              </Typography>
            </div>
            <Table
              className="mikromatik-users-table striped-table"
              columns={columns}
              dataSource={polices}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ChildPoliciesTable;
