import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import MikroMatikButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import MikroMatikSelect from "../../Items/Select/Index";
const CreatePackageForCompanyForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { intPkCompanyID } = useParams();
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateCompanyPackageApiProp>(
    "packages/createNewPackage",
    true
  );
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { packageName, subnet, parent_MaxLimit_AbbrvStr, speed } = values;
    const dataToSend: CreateCompanyPackageApiProp = {
      intPkCompanyID: Number(intPkCompanyID),
      packageName,
      subnet,
      parent_MaxLimit_AbbrvStr,
      speed: Number(speed),
    };
    postApiData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {};
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);

  return (
    <>
      {contextHolder}
      <Row style={{ width: "100%" }}>
        <Form
          name="createCompanyPackageForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Package Name</span>
                }
                name="packageName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Package",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Test Package"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Subnet</span>}
                name="subnet"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Subnet",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="123.123.123"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Up/Down Speed</span>
                }
                name="speed"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Speed",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Speed must be greater than 0")
                          ),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="10"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Speed Unit</span>
                }
                name="parent_MaxLimit_AbbrvStr"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Unit",
                  },
                ]}
              >
                <MikroMatikSelect
                  size="large"
                  height="45px"
                  options={[
                    { label: "K", value: "K" },
                    { label: "M", value: "M" },
                    { label: "G", value: "G" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <MikroMatikButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Create Package "
                  disabled={apiLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreatePackageForCompanyForm;
