import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import MikroMatikButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import CreateParentProfileModal from "../../Modals/CreateParentProfileModal";
import ParentPoliciesTable from "../../Items/Table/ParentPoliciesTable";
import CreateChildProfileModal from "../../Modals/CreateChildProfileModal";
import ViewChildPoliciesModal from "../../Modals/ViewChildPoliciesModal";
const CreatePolicyForPackage = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("packets/getPacketMarks");
  const [
    createNewProfileAPi,
    profileApiResponse,
    profileApiError,
    profileApiLoading,
    profileApiMessage,
    profileApiErrorCount,
  ] = useAxiosPost<CreateNewProfileApiProp>(
    "policies/createNewProfileForPackage",
    true
  );
  const [packetOptions, setPacketOptions] = useState<string[]>([]);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      const options = data.map((item: any) => item.packetMark);
      setPacketOptions(options);
    }
  }, [apiResponse]);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { intPkPackageID } = useParams();
  const [show, setShow] = useState<boolean>(false);
  const [showCreateChild, setShowCreateChild] = useState<boolean>(false);
  const [showViewChildren, setShowViewChildren] = useState<boolean>(false);
  const { openNotification, contextHolder } = useMultiNotification();
  const [activeParent, setActiveParent] = useState<number>(-1);
  const [policies, setPolicies] = useState<ParentPolicyProp[]>([]);
  const [activeChildToEdit, setActiveChildToEdit] =
    useState<ChildPolicyProp | null>(null);

  const [activeParentToEdit, setActiveParentToEdit] =
    useState<ParentPolicyProp | null>(null);
  const [activeChildPolicies, setActiveChildPolicies] = useState<
    ChildPolicyProp[]
  >([]);
  const handleClose = () => {
    setShow(false);
    setShowCreateChild(false);
    setShowViewChildren(false);
    setActiveParent(-1);
    setActiveChildToEdit(null);
    setActiveParentToEdit(null);
  };
  const handleAddNewParent = (data: ParentPolicyProp) => {
    console.log({ data });
    setPolicies([...policies, data]);
  };
  const handleCreateNewChildModal = (id: number) => {
    setActiveParent(id);
    setShowCreateChild(true);
  };
  const handleViewChildrenModal = (id: number) => {
    setActiveParent(id);
    const policy = policies.find((item) => Number(item.id) === id);
    if (policy) {
      setActiveChildPolicies(policy.childQueuesParamsArray);
      setShowViewChildren(true);
    }
  };
  const handleAddNewChild = (data: ChildPolicyProp) => {
    console.log({ data });
    const { parentId } = data;
    const updatedItems = policies.map((item) => {
      if (Number(item.id) === Number(parentId)) {
        return {
          ...item,
          childQueuesParamsArray: [...item.childQueuesParamsArray, data],
        };
      } else {
        return item;
      }
    });
    setPolicies(updatedItems);
  };
  const handleEditChildModal = (childId: number) => {
    setShowViewChildren(false);
    if (activeParent === -1) {
      return;
    }
    const item = policies.find(
      (item) => Number(item.id) === Number(activeParent)
    );
    if (item) {
      const child = item.childQueuesParamsArray.find(
        (item) => Number(item.id) === Number(childId)
      );
      if (child) {
        //lets format this child so that it fits in the modal form.
        const updatedChild: ChildPolicyProp = {
          child_PacketMarksArray: child.child_PacketMarksArray,
          id: child.id,
          parentId: child.parentId,
          childQueComment:
            child.childQueComment !== false ? child.childQueComment : "",
          childQueProfileDisplayName: child.childQueProfileDisplayName,
          child_BucketSize_DownloadInt:
            child.child_BucketSize_DownloadInt !== false
              ? child.child_BucketSize_DownloadInt
              : "0",
          child_BucketSize_UploadInt:
            child.child_BucketSize_UploadInt !== false
              ? child.child_BucketSize_UploadInt
              : "0",
          child_BurstLimit_AbbrvStr:
            child.child_BurstLimit_AbbrvStr !== false
              ? child.child_BurstLimit_AbbrvStr
              : "",
          child_BurstLimit_DownloadInt:
            child.child_BurstLimit_DownloadInt !== false
              ? child.child_BurstLimit_DownloadInt
              : 0,
          child_BurstLimit_UploadInt:
            child.child_BurstLimit_UploadInt !== false
              ? child.child_BurstLimit_UploadInt
              : 0,
          child_BurstThreshold_AbbrvStr:
            child.child_BurstThreshold_AbbrvStr !== false
              ? child.child_BurstThreshold_AbbrvStr
              : "",
          child_BurstThreshold_DownloadInt:
            child.child_BurstThreshold_DownloadInt !== false
              ? child.child_BurstThreshold_DownloadInt
              : 0,
          child_BurstThreshold_UploadInt:
            child.child_BurstThreshold_UploadInt !== false
              ? child.child_BurstThreshold_UploadInt
              : 0,
          child_BurstTimeSeconds_DownloadInt:
            child.child_BurstTimeSeconds_DownloadInt !== false
              ? child.child_BurstTimeSeconds_DownloadInt
              : 0,
          child_BurstTimeSeconds_UploadInt:
            child.child_BurstTimeSeconds_UploadInt !== false
              ? child.child_BurstTimeSeconds_UploadInt
              : 0,
          child_Dst_String:
            child.child_Dst_String !== false ? child.child_Dst_String : "",
          child_LimitAt_AbbrvStr:
            child.child_LimitAt_AbbrvStr !== false
              ? child.child_LimitAt_AbbrvStr
              : "",
          child_LimitAt_DownloadInt:
            child.child_LimitAt_DownloadInt !== false
              ? child.child_LimitAt_DownloadInt
              : 0,
          child_LimitAt_UploadInt:
            child.child_LimitAt_UploadInt !== false
              ? child.child_LimitAt_UploadInt
              : 0,
          child_MaxLimit_AbbrvStr:
            child.child_MaxLimit_AbbrvStr !== false
              ? child.child_MaxLimit_AbbrvStr
              : "",
          child_MaxLimit_DownloadInt:
            child.child_MaxLimit_DownloadInt !== false
              ? child.child_MaxLimit_DownloadInt
              : 0,
          child_MaxLimit_UploadInt:
            child.child_MaxLimit_UploadInt !== false
              ? child.child_MaxLimit_UploadInt
              : 0,
          child_Priority_DownloadInt:
            child.child_Priority_DownloadInt !== false
              ? child.child_Priority_DownloadInt
              : 0,
          child_Priority_UploadInt:
            child.child_Priority_UploadInt !== false
              ? child.child_Priority_UploadInt
              : 0,
          child_QueueType_DownloadType:
            child.child_QueueType_DownloadType !== false
              ? child.child_QueueType_DownloadType
              : "",
          child_QueueType_UploadType:
            child.child_QueueType_UploadType !== false
              ? child.child_QueueType_UploadType
              : "",
          child_Time_String:
            child.child_Time_String !== false ? child.child_Time_String : "",
          child_PacketMarkStrs:
            child.child_PacketMarkStrs !== false
              ? child.child_PacketMarkStrs
              : "",
        };
        setActiveChildToEdit(updatedChild);
        setShowCreateChild(true);
      }
    }
  };
  const handleUpdateChild = (data: ChildPolicyProp) => {
    const { parentId, id } = data;
    const updatedPolicies = policies.map((item) => {
      if (Number(item.id) === Number(parentId)) {
        const updatedChildren = item.childQueuesParamsArray.map((child) => {
          if (Number(child.id) === Number(id)) {
            return data;
          } else {
            return child;
          }
        });
        return {
          ...item,
          childQueuesParamsArray: updatedChildren,
        };
      } else {
        return item;
      }
    });
    setPolicies(updatedPolicies);
    setActiveChildToEdit(null);
  };
  const handleDeleteChild = (id: number) => {
    if (activeParent === -1) {
      return;
    }
    const updatedItems = policies.map((item) => {
      if (Number(item.id) === Number(activeParent)) {
        const updatedChild = item.childQueuesParamsArray.filter(
          (child) => child.id !== id
        );

        return {
          ...item,
          childQueuesParamsArray: updatedChild,
        };
      }
      return item;
    });
    const policy = updatedItems.find(
      (item) => Number(item.id) === Number(activeParent)
    );
    if (policy) {
      setActiveChildPolicies(policy.childQueuesParamsArray);
    }
    setPolicies(updatedItems);
  };
  const deleteParentProfile = (activeParent: number) => {
    const updatedItems = policies.filter(
      (item) => Number(item.id) !== Number(activeParent)
    );

    setPolicies(updatedItems);
  };
  const editParentProfile = (id: number) => {
    const policy = policies.find((item) => Number(item.id) === id);
    if (!policy) {
      return;
    }
    const {
      parentQueComment,
      parentQueProfileDisplayName,
      parent_BucketSize_DownloadInt,
      parent_BucketSize_UploadInt,
      parent_BurstLimit_AbbrvStr,
      parent_BurstLimit_DownloadInt,
      parent_BurstLimit_UploadInt,
      parent_BurstThreshold_AbbrvStr,
      parent_BurstThreshold_DownloadInt,
      parent_BurstThreshold_UploadInt,
      parent_BurstTimeSeconds_DownloadInt,
      parent_BurstTimeSeconds_UploadInt,
      parent_Dst_String,
      parent_LimitAt_AbbrvStr,
      parent_LimitAt_DownloadInt,
      parent_LimitAt_UploadInt,
      parent_MaxLimit_AbbrvStr,
      parent_MaxLimit_DownloadInt,
      parent_MaxLimit_UploadInt,
      parent_Priority_DownloadInt,
      parent_Priority_UploadInt,
      parent_QueueType_DownloadType,
      parent_QueueType_UploadType,
      parent_Time_String,
      parent_PacketMarkStrs,
      childQueuesParamsArray,
      parent_PacketMarksArray,
    } = policy;
    const updatedItem: ParentPolicyProp = {
      parent_PacketMarksArray,
      childQueuesParamsArray,
      id: policy.id,
      key: Number(policy.id),
      intPkPackageID: policy.intPkPackageID,
      parentQueComment:
        policy.parentQueComment !== false ? parentQueComment : "",
      parentQueProfileDisplayName,
      parent_BucketSize_DownloadInt:
        policy.parent_BucketSize_DownloadInt !== false
          ? parent_BucketSize_DownloadInt
          : "0",
      parent_BucketSize_UploadInt:
        policy.parent_BucketSize_UploadInt !== false
          ? parent_BucketSize_UploadInt
          : "0",
      parent_BurstLimit_AbbrvStr:
        policy.parent_BurstLimit_AbbrvStr !== false
          ? parent_BurstLimit_AbbrvStr
          : "",
      parent_BurstLimit_DownloadInt:
        policy.parent_BurstLimit_DownloadInt !== false
          ? parent_BurstLimit_DownloadInt
          : 0,
      parent_BurstLimit_UploadInt:
        policy.parent_BurstLimit_UploadInt !== false
          ? parent_BurstLimit_UploadInt
          : 0,
      parent_BurstThreshold_AbbrvStr:
        policy.parent_BurstThreshold_AbbrvStr !== false
          ? parent_BurstThreshold_AbbrvStr
          : "",
      parent_BurstThreshold_DownloadInt:
        policy.parent_BurstThreshold_DownloadInt !== false
          ? parent_BurstThreshold_DownloadInt
          : 0,
      parent_BurstThreshold_UploadInt:
        policy.parent_BurstThreshold_UploadInt !== false
          ? parent_BurstThreshold_UploadInt
          : 0,
      parent_BurstTimeSeconds_DownloadInt:
        policy.parent_BurstTimeSeconds_DownloadInt !== false
          ? parent_BurstTimeSeconds_DownloadInt
          : 0,
      parent_BurstTimeSeconds_UploadInt:
        policy.parent_BurstTimeSeconds_UploadInt !== false
          ? parent_BurstTimeSeconds_UploadInt
          : 0,
      parent_Dst_String:
        policy.parent_Dst_String !== false ? parent_Dst_String : "",
      parent_LimitAt_AbbrvStr:
        policy.parent_LimitAt_AbbrvStr !== false ? parent_LimitAt_AbbrvStr : "",
      parent_LimitAt_DownloadInt:
        policy.parent_LimitAt_DownloadInt !== false
          ? parent_LimitAt_DownloadInt
          : 0,
      parent_LimitAt_UploadInt:
        policy.parent_LimitAt_UploadInt !== false
          ? parent_LimitAt_UploadInt
          : 0,
      parent_MaxLimit_AbbrvStr:
        policy.parent_MaxLimit_AbbrvStr !== false
          ? parent_MaxLimit_AbbrvStr
          : "",
      parent_MaxLimit_DownloadInt:
        policy.parent_MaxLimit_DownloadInt !== false
          ? parent_MaxLimit_DownloadInt
          : 0,
      parent_MaxLimit_UploadInt:
        policy.parent_MaxLimit_UploadInt !== false
          ? parent_MaxLimit_UploadInt
          : 0,
      parent_Priority_DownloadInt:
        policy.parent_Priority_DownloadInt !== false
          ? parent_Priority_DownloadInt
          : 0,
      parent_Priority_UploadInt:
        policy.parent_Priority_UploadInt !== false
          ? parent_Priority_UploadInt
          : 0,
      parent_QueueType_DownloadType:
        policy.parent_QueueType_DownloadType !== false
          ? parent_QueueType_DownloadType
          : "",
      parent_QueueType_UploadType:
        policy.parent_QueueType_UploadType !== false
          ? parent_QueueType_UploadType
          : "",
      parent_Time_String:
        policy.parent_Time_String !== false ? parent_Time_String : "",
      parent_PacketMarkStrs:
        policy.parent_PacketMarkStrs !== false ? parent_PacketMarkStrs : "",
    };
    setActiveParentToEdit(updatedItem);
    setShow(true);
  };
  const handleUpdateParent = (data: ParentPolicyProp) => {
    const { id } = data;
    const updatedItems = policies.map((item) => {
      if (Number(item.id) === Number(id)) {
        return data;
      }
      return item;
    });

    setPolicies(updatedItems);
    setActiveParentToEdit(null);
  };
  const handleEditChildModalThroughExpandable = (
    childId: number,
    parentID: number
  ) => {
    setShowViewChildren(false);
    setActiveParent(parentID);
    const item = policies.find((item) => Number(item.id) === Number(parentID));
    if (item) {
      const child = item.childQueuesParamsArray.find(
        (item) => Number(item.id) === Number(childId)
      );
      if (child) {
        //lets format this child so that it fits in the modal form.
        const updatedChild: ChildPolicyProp = {
          child_PacketMarksArray: child.child_PacketMarksArray,
          id: child.id,
          parentId: child.parentId,
          childQueComment:
            child.childQueComment !== false ? child.childQueComment : "",
          childQueProfileDisplayName: child.childQueProfileDisplayName,
          child_BucketSize_DownloadInt:
            child.child_BucketSize_DownloadInt !== false
              ? child.child_BucketSize_DownloadInt
              : "0",
          child_BucketSize_UploadInt:
            child.child_BucketSize_UploadInt !== false
              ? child.child_BucketSize_UploadInt
              : "0",
          child_BurstLimit_AbbrvStr:
            child.child_BurstLimit_AbbrvStr !== false
              ? child.child_BurstLimit_AbbrvStr
              : "",
          child_BurstLimit_DownloadInt:
            child.child_BurstLimit_DownloadInt !== false
              ? child.child_BurstLimit_DownloadInt
              : 0,
          child_BurstLimit_UploadInt:
            child.child_BurstLimit_UploadInt !== false
              ? child.child_BurstLimit_UploadInt
              : 0,
          child_BurstThreshold_AbbrvStr:
            child.child_BurstThreshold_AbbrvStr !== false
              ? child.child_BurstThreshold_AbbrvStr
              : "",
          child_BurstThreshold_DownloadInt:
            child.child_BurstThreshold_DownloadInt !== false
              ? child.child_BurstThreshold_DownloadInt
              : 0,
          child_BurstThreshold_UploadInt:
            child.child_BurstThreshold_UploadInt !== false
              ? child.child_BurstThreshold_UploadInt
              : 0,
          child_BurstTimeSeconds_DownloadInt:
            child.child_BurstTimeSeconds_DownloadInt !== false
              ? child.child_BurstTimeSeconds_DownloadInt
              : 0,
          child_BurstTimeSeconds_UploadInt:
            child.child_BurstTimeSeconds_UploadInt !== false
              ? child.child_BurstTimeSeconds_UploadInt
              : 0,
          child_Dst_String:
            child.child_Dst_String !== false ? child.child_Dst_String : "",
          child_LimitAt_AbbrvStr:
            child.child_LimitAt_AbbrvStr !== false
              ? child.child_LimitAt_AbbrvStr
              : "",
          child_LimitAt_DownloadInt:
            child.child_LimitAt_DownloadInt !== false
              ? child.child_LimitAt_DownloadInt
              : 0,
          child_LimitAt_UploadInt:
            child.child_LimitAt_UploadInt !== false
              ? child.child_LimitAt_UploadInt
              : 0,
          child_MaxLimit_AbbrvStr:
            child.child_MaxLimit_AbbrvStr !== false
              ? child.child_MaxLimit_AbbrvStr
              : "",
          child_MaxLimit_DownloadInt:
            child.child_MaxLimit_DownloadInt !== false
              ? child.child_MaxLimit_DownloadInt
              : 0,
          child_MaxLimit_UploadInt:
            child.child_MaxLimit_UploadInt !== false
              ? child.child_MaxLimit_UploadInt
              : 0,
          child_Priority_DownloadInt:
            child.child_Priority_DownloadInt !== false
              ? child.child_Priority_DownloadInt
              : 0,
          child_Priority_UploadInt:
            child.child_Priority_UploadInt !== false
              ? child.child_Priority_UploadInt
              : 0,
          child_QueueType_DownloadType:
            child.child_QueueType_DownloadType !== false
              ? child.child_QueueType_DownloadType
              : "",
          child_QueueType_UploadType:
            child.child_QueueType_UploadType !== false
              ? child.child_QueueType_UploadType
              : "",
          child_Time_String:
            child.child_Time_String !== false ? child.child_Time_String : "",
          child_PacketMarkStrs:
            child.child_PacketMarkStrs !== false
              ? child.child_PacketMarkStrs
              : "",
        };
        setActiveChildToEdit(updatedChild);
        setShowCreateChild(true);
      }
    }
  };
  const handleDeleteChildThroughExpandable = (id: number, parentID: number) => {
    const updatedItems = policies.map((item) => {
      if (Number(item.id) === Number(parentID)) {
        const updatedChild = item.childQueuesParamsArray.filter(
          (child) => child.id !== id
        );

        return {
          ...item,
          childQueuesParamsArray: updatedChild,
        };
      }
      return item;
    });
    const policy = updatedItems.find(
      (item) => Number(item.id) === Number(parentID)
    );
    if (policy) {
      setActiveChildPolicies(policy.childQueuesParamsArray);
    }
    setPolicies(updatedItems);
  };
  const handleCreateNewProfile = () => {
    createNewProfileAPi({ policies: policies });
  };
  useEffect(() => {
    if (profileApiError !== null && profileApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", profileApiError);
    }
  }, [profileApiError, profileApiErrorCount]);
  useEffect(() => {
    if (profileApiResponse !== null) {
      // userType, email
      const { message } = profileApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    }
  }, [profileApiResponse]);
  return (
    <>
      {contextHolder}
      {show === true && intPkPackageID && (
        <CreateParentProfileModal
          intPkPackageID={Number(intPkPackageID)}
          show={show}
          handleClose={handleClose}
          handleAddNewParent={handleAddNewParent}
          activeParentToEdit={activeParentToEdit}
          handleUpdateParent={handleUpdateParent}
          packetMarks={packetOptions}
        />
      )}
      {showCreateChild === true && activeParent !== -1 && (
        <CreateChildProfileModal
          show={showCreateChild}
          handleClose={handleClose}
          parentId={activeParent}
          handleAddNewChild={handleAddNewChild}
          activeChildToEdit={activeChildToEdit}
          handleUpdateChild={handleUpdateChild}
          packetMarks={packetOptions}
        />
      )}
      {showViewChildren === true && activeParent !== -1 && (
        <ViewChildPoliciesModal
          show={showViewChildren}
          handleClose={handleClose}
          // handleAddNewChild={handleAddNewChild}
          policies={activeChildPolicies}
          handleEditChildModal={handleEditChildModal}
          handleDeleteChild={handleDeleteChild}
        />
      )}

      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        <Col span={6} offset={18}>
          <MikroMatikButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShow(true);
            }}
            text="Create New Profile"
          />
        </Col>
        {policies.length > 0 && (
          <>
            <Col span={24}>
              <ParentPoliciesTable
                isLoading={false}
                polices={policies}
                handleCreateNewChild={handleCreateNewChildModal}
                handleViewChildren={handleViewChildrenModal}
                deleteParentProfile={deleteParentProfile}
                editParentProfile={editParentProfile}
                handleDeleteChild={handleDeleteChildThroughExpandable}
                handleEditChild={handleEditChildModalThroughExpandable}
              />
            </Col>
            <Col span={6} offset={18}>
              <MikroMatikButton
                backgroundColor={ButtonProps.background.STANDARD}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.STANDARD}
                textColor={ButtonProps.color.STANDARD}
                disabled={profileApiLoading}
                onClick={() => {
                  handleCreateNewProfile();
                }}
                text="Submit"
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
export default CreatePolicyForPackage;

/**
 * 
 * 
 * 
 * const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateCompanyApiProp>(
    "auth/createNewAdminWithCompany",
    true
  );

  
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(`/`);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);
 */
