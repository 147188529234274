import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import AdminPackagesTable from "../../Items/Table/admin/AdminPackagesTable";
import ViewPoliciesByPackageTable from "../../Items/Table/ViewPoliciesByPackageTable";
const ViewPoliciesByPackage = () => {
  const { intPkPackageID } = useParams();

  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [policies, setPolicies] = useState<ParentPolicyProp[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetPoliciesByPackageApiProp>(
    "policies/getPoliciesByPackage"
  );
  useEffect(() => {
    postApiData({ intPkPackageID: Number(intPkPackageID) });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      const _data = data.map((item: any) => {
        return {
          ...item,
          id: item.intPkParentQueProfileID,
          key: item.intPkParentQueProfileID,
          parent_PacketMarksArray:
            item.parent_PacketMarkStrs === false
              ? []
              : item.parent_PacketMarkStrs.split(","),
          childQueuesParamsArray: item.childQueuesParamsArray.map(
            (_item: any) => {
              return {
                ..._item,
                id: _item.intPkChildQueProfileID,
                key: _item.intPkChildQueProfileID,
                child_PacketMarksArray:
                  _item.child_PacketMarkStrs === false
                    ? []
                    : _item.child_PacketMarkStrs.split(","),
              };
            }
          ),
        };
      });
      setPolicies(_data);
    }
  }, [apiResponse]);
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        <Col span={24}>
          <ViewPoliciesByPackageTable
            polices={policies}
            isLoading={apiLoading}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewPoliciesByPackage;
