import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import { getUserType } from "../helpers/userDetails";
import ViewStaffByCompany from "../components/Company/Views/ViewStaffByCompany";

const ViewStaffByCompanyPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Company Staff");
  }, [setTitle]);

  return (
    <>
      <ViewStaffByCompany />
    </>
  );
};
export default ViewStaffByCompanyPage;
