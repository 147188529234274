import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonProps } from "../../../helpers/constants";
import MikroMatikButton from "../../Items/Button/Index";
import CompanyMikroTiksTable from "../../Items/Table/MikroTiksTable";
const ViewMikroTiksByCompany = () => {
  const navigate = useNavigate();
  const { intPkCompanyID } = useParams();
  const { openNotification, contextHolder } = useMultiNotification();
  const [mikrotiks, setMikrotiks] = useState<MikroTikSimple[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetCompaniesApiProp>("mikrotiks/getCompanyMikrotiks");
  useEffect(() => {
    postApiData({ intPkCompanyID: Number(intPkCompanyID) });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      setMikrotiks(data || []);
    }
  }, [apiResponse]);
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        <Col span={6} offset={18}>
          <MikroMatikButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              navigate(`/create-mikrotik/${intPkCompanyID}`);
            }}
            text="Create Mikrotik"
          />
        </Col>
        <Col span={24}>
          <CompanyMikroTiksTable mikrotiks={mikrotiks} isLoading={apiLoading} />
        </Col>
      </Row>
    </>
  );
};
export default ViewMikroTiksByCompany;
