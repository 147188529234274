export const removeSpacesFromString = (str: string): string => {
  return str.split(" ").join("");
};
export const getUnitFromKMG = (value: string) => {
  if (value === "K") {
    return "Kb";
  }
  if (value === "M") {
    return "Mb";
  }
  if (value === "G") {
    return "Gb";
  }
  return value;
};
export const getStringOrFalse = (value: string): BooleanOrString => {
  if (!value) {
    return false;
  }
  return value;
};
export const getNumberOrFalse = (value: string): NumberOrBoolean => {
  if (!value) {
    return false;
  }
  if (Number(value) < 1) {
    return false;
  }
  return Number(value);
};
