import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewMyPackages from "../components/Admin/Views/ViewMyPackages";

const ViewMyPackagesPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Packages");
  }, [setTitle]);

  return (
    <>
      <ViewMyPackages />
    </>
  );
};
export default ViewMyPackagesPage;
