import React, { useState } from "react";
import MikroMatikModal from "../Items/Modal/Index";

import "./styles/index.css";

import useMultiNotification from "../../hooks/useNotification";
import { Col, Row } from "antd";
import ChildPoliciesTable from "../Items/Table/ChildPoliciesTable";

const ViewChildPoliciesModal: React.FC<ViewChildPoliciesModal> = ({
  show,
  handleClose,
  policies,
  handleEditChildModal,
  handleDeleteChild,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleEditChild = (childId: number) => {
    console.log({ childId });
    handleEditChildModal(childId);
  };
  return (
    <>
      <MikroMatikModal
        title="View Child Profiles"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        minWidthPercentage="70%"
        open={show}
        onCancel={() => {
          // if (apiLoading) {
          //   return;
          // }
          handleClose(false);
        }}
      >
        {contextHolder}
        <Row>
          <Col span={20} offset={2} style={{ marginBottom: "50px" }}>
            <ChildPoliciesTable
              polices={policies}
              isLoading={false}
              handleEditChild={handleEditChild}
              handleDeleteChild={handleDeleteChild}
            />
          </Col>
        </Row>
      </MikroMatikModal>
    </>
  );
};

export default ViewChildPoliciesModal;
