import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import MikroMatikButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import MikroMatikSelect from "../../Items/Select/Index";
const CreateMikroTikForCompanyForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { intPkCompanyID } = useParams();
  const { openNotification, contextHolder } = useMultiNotification();
  const [probes, setProbes] = useState<MikroMatikSelectOptions[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateMikrotikForCompanyApiProp>(
    "mikrotiks/createNewMikroTik",
    true
  );
  const [getMikrotikProbesApi, mikrotikProbesApiResponse] =
    useAxiosPost<ResponseProp>("bots/getMikrotikBotProbes");
  useEffect(() => {
    getMikrotikProbesApi({});
  }, []);
  useEffect(() => {
    if (mikrotikProbesApiResponse !== null) {
      // userType, email
      const data: any = mikrotikProbesApiResponse.responseData;
      setProbes(
        data.map((item: any) => {
          return {
            value: item.intPkMikroTikBotProbeID,
            label: item.probeIP,
          };
        })
      );
    }
  }, [mikrotikProbesApiResponse]);
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const {
      intPkMikroTikBotProbeID,
      nasPort,
      nasIpAddress,
      mikrotikName,
      nasUsername,
      nasPassword,
      applyQosPolicyByInterfaceOrIPAddress,
      includeTargetInChildPolicy,
      policyNameHeadPrefix,
      manualPolicyPrefix,
    } = values;

    const dataToSend: CreateMikrotikForCompanyApiProp = {
      intPkCompanyID: Number(intPkCompanyID),
      intPkMikroTikBotProbeID: Number(intPkMikroTikBotProbeID),
      nasPort: Number(nasPort),
      nasIpAddress,
      mikrotikName,
      nasUsername,
      nasPassword,
      identifyConnectionBySubnetOrUsername: "SUBNET",
      applyQosPolicyByInterfaceOrIPAddress,
      includeTargetInChildPolicy:
        Number(includeTargetInChildPolicy) === 1 ? true : false,
      policyNameHeadPrefix,
      manualPolicyPrefix,
    };
    postApiData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {};
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);

  return (
    <>
      {contextHolder}
      <Row style={{ width: "100%" }}>
        <Form
          name="createCompanyPackageForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">NAS Name</span>}
                name="mikrotikName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter NAS Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Test NAS"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">NAS IP</span>}
                name="nasIpAddress"
                rules={[
                  {
                    required: true,
                    message: "Please Enter NAS IP",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="123.123.123.123"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">UserName</span>}
                name="nasUsername"
                rules={[
                  {
                    required: true,
                    message: "Please Enter username",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="someTestUserName"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Password</span>}
                name="nasPassword"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Password",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="someTestPassword"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">NAS Port</span>}
                name="nasPort"
                rules={[
                  {
                    required: true,
                    message: "Please Enter NAS PORT",
                  },
                  {
                    validator: (_, value) =>
                      value > 1
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("NAS PORT Must be greater than 1")
                          ),
                  },
                  {
                    validator: (_, value) =>
                      value > 65535
                        ? Promise.reject(
                            new Error("NAS PORT Must be less than 65535")
                          )
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="10"
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Mikrotik Probe</span>
                }
                name="intPkMikroTikBotProbeID"
                rules={[
                  {
                    required: true,
                    message: "Please Choose Mikrotik Probe",
                  },
                ]}
              >
                <MikroMatikSelect size="large" height="45px" options={probes} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">
                    QoS Policy Interface
                  </span>
                }
                name="applyQosPolicyByInterfaceOrIPAddress"
                rules={[
                  {
                    required: true,
                    message: "Please Choose  QoS Policy Interface Type",
                  },
                ]}
              >
                <MikroMatikSelect
                  size="large"
                  height="45px"
                  options={[
                    { label: "By Interface", value: "INTERFACE" },
                    { label: "By IP Address", value: "IPADDRESS" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Child Policy Inclusion
                  </span>
                }
                name="includeTargetInChildPolicy"
                rules={[
                  {
                    required: true,
                    message: "Please Choose Including Target Child Policy",
                  },
                ]}
              >
                <MikroMatikSelect
                  size="large"
                  height="45px"
                  options={[
                    { label: "Yes", value: 1 },
                    { label: "No", value: 0 },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Policy Name Prefix
                  </span>
                }
                name="policyNameHeadPrefix"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Policy Name Prefix",
                  },
                  {
                    max: 30,
                    message: "Max Character length is 30",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="somePrefix"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Policy Name Prefix
                  </span>
                }
                name="manualPolicyPrefix"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Manual Policy Prefix",
                  },
                  {
                    max: 30,
                    message: "Max Character length is 30",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="somePrefix"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <MikroMatikButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Submit"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreateMikroTikForCompanyForm;
