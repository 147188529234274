import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewPoliciesByPackage from "../components/Admin/Views/ViewPoliciesByPackage";

const ViewPoliciesForPackagePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("View Policies");
  }, [setTitle]);

  return (
    <>
      <ViewPoliciesByPackage />
    </>
  );
};
export default ViewPoliciesForPackagePage;
