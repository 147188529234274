import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../../Loaders/TailSpinLoader";
import { formatDate } from "../../../../helpers/date";

import { MoreOutlined } from "@ant-design/icons";
import "./../index.css";
import { getUnitFromKMG } from "../../../../helpers/strings";
const AdminPackagesTable: React.FC<PackagesTableProp> = ({
  packages,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState<SimplePackageProp | null>(null);
  const packageOptionItems: MenuProps["items"] = [
    {
      key: "VIEW_POLICIES",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={ProfileIconBlue} /> */}
          View Policies
        </Typography>
      ),
    },
    {
      key: "CREATE_POLICY",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={ProfileIconBlue} /> */}
          Create Policy
        </Typography>
      ),
    },
  ];

  const columns: TableProps<SimplePackageProp>["columns"] = [
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Subnet",
      dataIndex: "subnet",
      key: "subnet",
    },
    {
      title: "Speed",
      render: (row) => (
        <span>
          {row.upSpeed}/{row.downSpeed}
          {row.parent_MaxLimit_AbbrvStr
            ? getUnitFromKMG(row.parent_MaxLimit_AbbrvStr)
            : ""}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: packageOptionItems,

            onClick: handleMenuClick,
          }}
          placement="bottomLeft"
        >
          <MoreOutlined
            onMouseOver={(e) => {
              e.preventDefault();
              setActiveRow(row);
            }}
          />
        </Dropdown>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }

    if (e.key === "VIEW_POLICIES") {
      navigate(`/view-policies-by-package/${activeRow.intPkPackageID}`);
    }
    if (e.key === "CREATE_POLICY") {
      navigate(`/create-policies-for-package/${activeRow.intPkPackageID}`);
    }
  };
  return (
    <div className="mikromatik-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="mikromatik-user-tables-heading">
                My Packages
              </Typography>
            </div>
            <Table
              className="mikromatik-users-table striped-table"
              columns={columns}
              dataSource={packages}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default AdminPackagesTable;
