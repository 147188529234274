import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewMyPackages from "../components/Admin/Views/ViewMyPackages";
import ViewMyStaff from "../components/Admin/Views/ViewMyStaff";

const ViewMyStaffPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Staff");
  }, [setTitle]);

  return (
    <>
      <ViewMyStaff />
    </>
  );
};
export default ViewMyStaffPage;
