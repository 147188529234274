import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CreateCompanyForm from "../components/Company/Forms/CreateCompany";

const CreateCompanyPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create Company");
  }, [setTitle]);

  return (
    <>
      <CreateCompanyForm />
    </>
  );
};
export default CreateCompanyPage;
