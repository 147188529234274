import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDate } from "../../../helpers/date";

import { MoreOutlined } from "@ant-design/icons";
import "./index.css";
import { getUnitFromKMG } from "../../../helpers/strings";
const CompanyPackagesTable: React.FC<PackagesTableProp> = ({
  packages,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState<SimplePackageProp | null>(null);
  const packageOptionItems: MenuProps["items"] = [
    {
      key: "PACKAGES",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={ProfileIconBlue} /> */}
          View Packages
        </Typography>
      ),
    },
    {
      key: "MIKROTIKS",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={KeyIcon} /> */}
          View MikroTiks
        </Typography>
      ),
    },
  ];

  const columns: TableProps<SimplePackageProp>["columns"] = [
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Subnet",
      dataIndex: "subnet",
      key: "subnet",
    },
    {
      title: "Speed",
      render: (row) => (
        <span>
          {row.upSpeed}/{row.downSpeed}
          {row.parent_MaxLimit_AbbrvStr
            ? getUnitFromKMG(row.parent_MaxLimit_AbbrvStr)
            : ""}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    // {
    //   title: "Action",
    //   render: (row) => (
    //     <Dropdown
    //       menu={{
    //         items: packageOptionItems,

    //         onClick: handleMenuClick,
    //       }}
    //       placement="bottomLeft"
    //     >
    //       <MoreOutlined
    //         onMouseOver={(e) => {
    //           e.preventDefault();
    //           setActiveRow(row);
    //         }}
    //       />
    //     </Dropdown>
    //   ),
    // },
  ];
  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    if (e.key === "PACKAGES") {
      navigate(`/view-packages-by-company/${activeRow.intPkCompanyID}`);
    } else if (e.key === "MIKROTIKS") {
      navigate(`/view-mikrotiks-by-company/${activeRow.intPkCompanyID}`);
    }
  };
  return (
    <div className="mikromatik-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="mikromatik-user-tables-heading">
                Company Packages
              </Typography>
            </div>
            <Table
              className="mikromatik-users-table striped-table"
              columns={columns}
              dataSource={packages}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default CompanyPackagesTable;
