import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDate } from "../../../helpers/date";

import { MoreOutlined } from "@ant-design/icons";
import "./index.css";
import { getUnitFromKMG } from "../../../helpers/strings";
const ViewPoliciesByPackageTable: React.FC<ViewPoliciesByPackageTableProp> = ({
  polices,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState<ParentPolicyProp | null>(null);
  const [activeChildRow, setActiveChildRow] = useState<ChildPolicyProp | null>(
    null
  );
  const parentPolicyOptionItems: MenuProps["items"] = [
    {
      key: "DELETE",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={ProfileIconBlue} /> */}
          Delete This Policy
        </Typography>
      ),
    },

    {
      key: "EDIT",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={ProfileIconBlue} /> */}
          Edit This Policy
        </Typography>
      ),
    },
    // {
    //   key: "VIEW",
    //   label: (
    //     <Typography className="profile-drop-down-item">
    //       {/* <img src={ProfileIconBlue} /> */}
    //       View Child Policies
    //     </Typography>
    //   ),
    // },
    {
      key: "CREATE",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={KeyIcon} /> */}
          Create Child Policy
        </Typography>
      ),
    },
  ];
  const childPolicyItemOptions: MenuProps["items"] = [
    {
      key: "EDIT",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={KeyIcon} /> */}
          Edit Policy
        </Typography>
      ),
    },
    {
      key: "DELETE",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={KeyIcon} /> */}
          Delete Policy
        </Typography>
      ),
    },
  ];
  const columns: TableProps<ParentPolicyProp>["columns"] = [
    {
      title: "Name",
      dataIndex: "parentQueProfileDisplayName",
      key: "parentQueProfileDisplayName",
    },
    {
      title: "Time",
      dataIndex: "parent_Time_String",
      key: "parent_Time_String",
    },
    {
      title: "Packets",
      render: (row) =>
        row.parent_PacketMarkStrs !== false ? row.parent_PacketMarkStrs : "",
    },
    {
      title: "maxLimitUP/DN",
      render: (row) => (
        <span>
          {row.parent_MaxLimit_UploadInt ? row.parent_MaxLimit_UploadInt : ""}{" "}
          {row.parent_MaxLimit_AbbrvStr
            ? getUnitFromKMG(row.parent_MaxLimit_AbbrvStr)
            : ""}
          /
          {row.parent_MaxLimit_DownloadInt
            ? row.parent_MaxLimit_DownloadInt
            : ""}
          {row.parent_MaxLimit_AbbrvStr
            ? getUnitFromKMG(row.parent_MaxLimit_AbbrvStr)
            : ""}
        </span>
      ),
    },
    {
      title: "Priority",
      render: (row) => (
        <>
          {row.parent_Priority_UploadInt && row.parent_Priority_UploadInt} /
          {row.parent_Priority_DownloadInt && row.parent_Priority_DownloadInt}
        </>
      ),
    },

    // {
    //   title: "Action",
    //   render: (row) => (
    //     <Dropdown
    //       menu={{
    //         items: parentPolicyOptionItems,

    //         onClick: handleMenuClick,
    //       }}
    //       placement="bottomLeft"
    //     >
    //       <MoreOutlined
    //         onMouseOver={(e) => {
    //           e.preventDefault();
    //           setActiveRow(row);
    //         }}
    //       />
    //     </Dropdown>
    //   ),
    // },
  ];
  // const handleChildMenuClick = (e: any) => {
  //   if (!activeChildRow) {
  //     return;
  //   }
  //   if (e.key === "EDIT") {
  //     handleEditChild(activeChildRow?.id || 0, activeChildRow.parentId);
  //   }
  //   if (e.key === "DELETE") {
  //     handleDeleteChild(activeChildRow?.id || 0, activeChildRow.parentId);
  //   }
  // };
  // const handleMenuClick = (e: any) => {
  //   if (!activeRow) {
  //     return;
  //   }
  //   // if (e.key === "VIEW") {
  //   //   handleViewChildren(activeRow?.id || 0);
  //   // } else

  //   if (e.key === "CREATE") {
  //     handleCreateNewChild(activeRow?.id || 0);
  //   } else if (e.key === "DELETE") {
  //     deleteParentProfile(activeRow?.id || 0);
  //   } else if (e.key === "EDIT") {
  //     editParentProfile(activeRow?.id || 0);
  //   }
  // };
  const expandedRowRender = (_data: ParentPolicyProp) => {
    const columns: TableProps<ChildPolicyProp>["columns"] = [
      {
        title: "Name",
        dataIndex: "childQueProfileDisplayName",
        key: "childQueProfileDisplayName",
      },

      {
        title: "Time",
        dataIndex: "child_Time_String",
        key: "child_Time_String",
      },
      {
        title: "Packets",
        render: (row) =>
          row.child_PacketMarkStrs !== false ? row.child_PacketMarkStrs : "",
      },
      {
        title: "maxLimitUP/DN",
        render: (row) => (
          <span>
            {row.child_MaxLimit_UploadInt ? row.child_MaxLimit_UploadInt : ""}
            {row.child_MaxLimit_AbbrvStr
              ? getUnitFromKMG(row.child_MaxLimit_AbbrvStr)
              : ""}
            /
            {row.child_MaxLimit_DownloadInt
              ? row.child_MaxLimit_DownloadInt
              : ""}
            {row.child_MaxLimit_AbbrvStr
              ? getUnitFromKMG(row.child_MaxLimit_AbbrvStr)
              : ""}
          </span>
        ),
      },
      {
        title: "Priority",
        render: (row) => (
          <>
            {row.child_Priority_UploadInt && row.child_Priority_UploadInt} /
            {row.child_Priority_DownloadInt && row.child_Priority_DownloadInt}
          </>
        ),
      },

      // {
      //   title: "Action",
      //   render: (row) => (
      //     <Dropdown
      //       menu={{
      //         items: childPolicyItemOptions,

      //         onClick: handleChildMenuClick,
      //       }}
      //       placement="bottomLeft"
      //     >
      //       <MoreOutlined
      //         onMouseOver={(e) => {
      //           e.preventDefault();
      //           setActiveChildRow(row);
      //         }}
      //       />
      //     </Dropdown>
      //   ),
      // },
    ];

    return (
      <Table
        columns={columns}
        dataSource={_data.childQueuesParamsArray}
        pagination={false}
      />
    );
  };
  return (
    <div className="mikromatik-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="mikromatik-user-tables-heading">
                Package Policy Profiles
              </Typography>
            </div>
            <Table
              className="mikromatik-users-table striped-table"
              columns={columns}
              dataSource={polices}
              pagination={false}
              expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewPoliciesByPackageTable;
