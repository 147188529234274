import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import AdminMikroTiksTable from "../../Items/Table/admin/AdminMikroTiksTable";
const ViewMyMikrotiks = () => {
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [mikrotiks, setMikrotiks] = useState<MikrotikMini[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("mikrotiks/getCompanyMikrotiksForAdmin");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      setMikrotiks(data || []);
    }
  }, [apiResponse]);
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        <Col span={24}>
          <AdminMikroTiksTable mikrotiks={mikrotiks} isLoading={apiLoading} />
        </Col>
      </Row>
    </>
  );
};
export default ViewMyMikrotiks;
