import HomeIcon from "./assets/svgs/MenuIcons/dashboard.svg";
import PackageIcon from "./assets/svgs/MenuIcons/Package.svg";
import StaffIcon from "./assets/svgs/MenuIcons/StaffIcon.svg";

//Active
import HomeBlue from "./assets/svgs/MenuIcons/dashboardBlue.svg";
import PackageBlue from "./assets/svgs/MenuIcons/Package.svg";
import StaffBlue from "./assets/svgs/MenuIcons/StaffBlue.svg";

import HomePage from "./pages/HomePage";
import { getUserType } from "./helpers/userDetails";
import CreateCompanyPage from "./pages/CreateCompanyPage";
import ViewPackagesByCompanyPage from "./pages/ViewPackagesByCompanyPage";
import CreatePackageForCompanyPage from "./pages/CreatePackageForCompanyPage";
import CreateMikroTikForCompanyPage from "./pages/CreateMikroTikForCompanyPage";
import ViewMikrotiksByCompanyPage from "./pages/ViewMikrotiksByCompanyPage";
import ViewMyPackagesPage from "./pages/ViewMyPackagesPage";
import ViewStaffByCompanyPage from "./pages/ViewStaffByCompanyPage";
import ViewMyStaffPage from "./pages/ViewMyStaffPage";
import CreatePolicyForPackagePage from "./pages/CreatePolicyForPackagePage";
import ViewPoliciesForPackagePage from "./pages/ViewPoliciesForPackagePage";

const routesList: Array<Route> = [
  {
    path: "/",
    Component: HomePage,
    roles: ["SUPER_ADMIN", "ADMIN"],
    text: "Home",
    show: true,
    icon: HomeIcon,
    activeIcon: HomeBlue,
  },
  {
    path: "/my-packages",
    Component: ViewMyPackagesPage,
    roles: ["ADMIN"],
    text: "Packages",
    show: true,
    icon: PackageIcon,
    activeIcon: PackageBlue,
  },
  {
    path: "/create-company",
    Component: CreateCompanyPage,
    roles: ["SUPER_ADMIN"],
    text: "Create Company",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/view-packages-by-company/:intPkCompanyID",
    Component: ViewPackagesByCompanyPage,
    roles: ["SUPER_ADMIN"],
    text: "Create Company",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/view-staff-by-company/:intPkCompanyID",
    Component: ViewStaffByCompanyPage,
    roles: ["SUPER_ADMIN"],
    text: "View Staff",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/create-package/:intPkCompanyID",
    Component: CreatePackageForCompanyPage,
    roles: ["SUPER_ADMIN"],
    text: "Create Package",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/create-mikrotik/:intPkCompanyID",
    Component: CreateMikroTikForCompanyPage,
    roles: ["SUPER_ADMIN"],
    text: "Create Mikrotik",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/view-mikrotiks-by-company/:intPkCompanyID",
    Component: ViewMikrotiksByCompanyPage,
    roles: ["SUPER_ADMIN"],
    text: "View Mikrotik",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/staff",
    Component: ViewMyStaffPage,
    roles: ["ADMIN"],
    text: "Staff",
    show: true,
    icon: StaffIcon,
    activeIcon: StaffBlue,
  },
  {
    path: "/create-policies-for-package/:intPkPackageID",
    Component: CreatePolicyForPackagePage,
    roles: ["ADMIN"],
    text: "Create Policy",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/view-policies-by-package/:intPkPackageID",
    Component: ViewPoliciesForPackagePage,
    roles: ["ADMIN"],
    text: "Create Policy",
    show: false,
    icon: "",
    activeIcon: "",
  },
];
export const AppRoutes = () => {
  const role = getUserType();
  return routesList.filter((route: Route) => route.roles.includes(role));
};
