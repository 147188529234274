import React, { useState } from "react";
import MikroMatikModal from "../Items/Modal/Index";

import "./styles/index.css";

import useMultiNotification from "../../hooks/useNotification";
import { Col, Form, Input, Row, Select } from "antd";
import MikroMatikButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import MikroMatikSelect from "../Items/Select/Index";
import { getNumberOrFalse, getStringOrFalse } from "../../helpers/strings";
const { TextArea } = Input;

const initialValues = {
  parent_MaxLimit_UploadInt: 0,
  parent_MaxLimit_DownloadInt: 0,
  parent_LimitAt_UploadInt: 0,
  parent_LimitAt_DownloadInt: 0,
  parent_BucketSize_UploadInt: 0,
  parent_BucketSize_DownloadInt: 0,
  parent_BurstLimit_UploadInt: 0,
  parent_BurstLimit_DownloadInt: 0,
  parent_BurstThreshold_UploadInt: 0,
  parent_BurstThreshold_DownloadInt: 0,
  parent_BurstTimeSeconds_UploadInt: 0,
  parent_BurstTimeSeconds_DownloadInt: 0,
};
const CreateParentProfileModal: React.FC<CreateParentProfileModalProps> = ({
  show,
  intPkPackageID,
  activeParentToEdit,
  packetMarks,
  handleClose,
  handleAddNewParent,
  handleUpdateParent,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (values: any) => {
    const {
      parentQueComment,
      parentQueProfileDisplayName,
      parent_BucketSize_DownloadInt,
      parent_BucketSize_UploadInt,
      parent_BurstLimit_AbbrvStr,
      parent_BurstLimit_DownloadInt,
      parent_BurstLimit_UploadInt,
      parent_BurstThreshold_AbbrvStr,
      parent_BurstThreshold_DownloadInt,
      parent_BurstThreshold_UploadInt,
      parent_BurstTimeSeconds_DownloadInt,
      parent_BurstTimeSeconds_UploadInt,
      parent_Dst_String,
      parent_LimitAt_AbbrvStr,
      parent_LimitAt_DownloadInt,
      parent_LimitAt_UploadInt,
      parent_MaxLimit_AbbrvStr,
      parent_MaxLimit_DownloadInt,
      parent_MaxLimit_UploadInt,
      parent_Priority_DownloadInt,
      parent_Priority_UploadInt,
      parent_QueueType_DownloadType,
      parent_QueueType_UploadType,
      parent_Time_String,
      parent_PacketMarksArray,
    } = values;
    console.log({ parent_PacketMarksArray });

    const Obj: ParentPolicyProp = {
      parent_PacketMarksArray: parent_PacketMarksArray,
      id: activeParentToEdit === null ? Math.random() : activeParentToEdit.id,
      key:
        activeParentToEdit === null
          ? Math.random()
          : Number(activeParentToEdit.id),
      parent_PacketMarkStrs:
        parent_PacketMarksArray.length < 1
          ? false
          : parent_PacketMarksArray.join(","),
      intPkPackageID: intPkPackageID,
      parent_Time_String: getStringOrFalse(parent_Time_String),
      parentQueComment: getStringOrFalse(parentQueComment),
      parent_QueueType_DownloadType: getStringOrFalse(
        parent_QueueType_DownloadType
      ),
      parent_QueueType_UploadType: getStringOrFalse(
        parent_QueueType_UploadType
      ),
      parentQueProfileDisplayName,
      parent_BucketSize_DownloadInt: getStringOrFalse(
        parent_BucketSize_DownloadInt
      ),
      parent_MaxLimit_AbbrvStr: getStringOrFalse(parent_MaxLimit_AbbrvStr),
      parent_BucketSize_UploadInt: getStringOrFalse(
        parent_BucketSize_UploadInt
      ),
      parent_BurstLimit_AbbrvStr: getStringOrFalse(parent_BurstLimit_AbbrvStr),
      parent_Dst_String: getStringOrFalse(parent_Dst_String),
      parent_LimitAt_AbbrvStr: getStringOrFalse(parent_LimitAt_AbbrvStr),
      parent_BurstLimit_DownloadInt: getNumberOrFalse(
        parent_BurstLimit_DownloadInt
      ),
      parent_BurstLimit_UploadInt: getNumberOrFalse(
        parent_BurstLimit_UploadInt
      ),
      parent_BurstThreshold_AbbrvStr: getStringOrFalse(
        parent_BurstThreshold_AbbrvStr
      ),
      parent_BurstThreshold_DownloadInt: getNumberOrFalse(
        parent_BurstThreshold_DownloadInt
      ),
      parent_BurstThreshold_UploadInt: getNumberOrFalse(
        parent_BurstThreshold_UploadInt
      ),
      parent_BurstTimeSeconds_DownloadInt: getNumberOrFalse(
        parent_BurstTimeSeconds_DownloadInt
      ),
      parent_BurstTimeSeconds_UploadInt: getNumberOrFalse(
        parent_BurstTimeSeconds_UploadInt
      ),
      parent_LimitAt_DownloadInt: getNumberOrFalse(parent_LimitAt_DownloadInt),
      parent_LimitAt_UploadInt: getNumberOrFalse(parent_LimitAt_UploadInt),
      parent_MaxLimit_DownloadInt: getNumberOrFalse(
        parent_MaxLimit_DownloadInt
      ),
      parent_MaxLimit_UploadInt: getNumberOrFalse(parent_MaxLimit_UploadInt),
      parent_Priority_DownloadInt: getNumberOrFalse(
        parent_Priority_DownloadInt
      ),
      parent_Priority_UploadInt: getNumberOrFalse(parent_Priority_UploadInt),
      childQueuesParamsArray:
        activeParentToEdit === null
          ? []
          : activeParentToEdit.childQueuesParamsArray,
    };
    if (activeParentToEdit === null) {
      handleAddNewParent(Obj);
      handleClose(false);
    } else {
      handleUpdateParent(Obj);
      handleClose(false);
    }
  };

  return (
    <>
      <MikroMatikModal
        title={
          activeParentToEdit === null
            ? "Create Parent Profile"
            : "Update Parent Profile"
        }
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        minWidthPercentage="60%"
        open={show}
        onCancel={() => {
          // if (apiLoading) {
          //   return;
          // }
          handleClose(false);
        }}
      >
        {contextHolder}
        <Row>
          <Col span={20} offset={2}>
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="createParentProfileOfPolicyForm"
              initialValues={
                activeParentToEdit === null ? initialValues : activeParentToEdit
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Profile Display Name
                      </span>
                    }
                    name="parentQueProfileDisplayName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Name",
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Packet Marks
                      </span>
                    }
                    name="parent_PacketMarksArray"
                  >
                    <Select
                      // value={selectedItems}
                      // onChange={setSelectedItems}
                      size="large"
                      mode="multiple"
                      // value={selectedItems}
                      // onChange={setSelectedItems}
                      style={{ width: "100%", minHeight: "45px" }}
                      options={packetMarks.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Second */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Upload Limit
                      </span>
                    }
                    name="parent_MaxLimit_UploadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Download Limit
                      </span>
                    }
                    name="parent_MaxLimit_DownloadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Third */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Upload Limit At
                      </span>
                    }
                    name="parent_LimitAt_UploadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Download Limit At
                      </span>
                    }
                    name="parent_LimitAt_DownloadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Fourth */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Limit Unit
                      </span>
                    }
                    name="parent_MaxLimit_AbbrvStr"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "K", value: "K" },
                        { label: "M", value: "M" },
                        { label: "G", value: "G" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Limit At Unit
                      </span>
                    }
                    name="parent_LimitAt_AbbrvStr"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "K", value: "K" },
                        { label: "M", value: "M" },
                        { label: "G", value: "G" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  Fifth*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Parent Priority Upload
                      </span>
                    }
                    name="parent_Priority_UploadInt"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                        { label: "6", value: "6" },
                        { label: "7", value: "7" },
                        { label: "8", value: "8" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Parent Priority Download
                      </span>
                    }
                    name="parent_Priority_DownloadInt"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                        { label: "6", value: "6" },
                        { label: "7", value: "7" },
                        { label: "8", value: "8" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  Sixth*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Bucket Upload Limit
                      </span>
                    }
                    name="parent_BucketSize_UploadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Bucket Download Limit
                      </span>
                    }
                    name="parent_BucketSize_DownloadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  Seventh*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Upload Burst Limit
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="parent_BurstLimit_UploadInt"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Download Burst Limit
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="parent_BurstLimit_DownloadInt"
                  >
                    <Input
                      placeholder="1"
                      type="number"
                      className="user-input-general-height"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  8th*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Upload Burst Threshold Limit
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="parent_BurstThreshold_UploadInt"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Download Burst Threshold Limit
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="parent_BurstThreshold_DownloadInt"
                  >
                    <Input
                      placeholder="1"
                      type="number"
                      className="user-input-general-height"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  9th*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Upload Queue Type
                      </span>
                    }
                    name="parent_QueueType_UploadType"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="enter type"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Download Queue Type
                      </span>
                    }
                    name="parent_QueueType_DownloadType"
                  >
                    <Input
                      placeholder="enter type"
                      className="user-input-general-height"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* 10Th */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">Burst Unit</span>
                    }
                    name="parent_BurstLimit_AbbrvStr"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "K", value: "K" },
                        { label: "M", value: "M" },
                        { label: "G", value: "G" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Burst Threshold Unit
                      </span>
                    }
                    name="parent_BurstThreshold_AbbrvStr"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "K", value: "K" },
                        { label: "M", value: "M" },
                        { label: "G", value: "G" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  11Th*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Burst Download Time Seconds
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="parent_BurstTimeSeconds_UploadInt"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Burst Download Time Seconds
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="parent_BurstTimeSeconds_DownloadInt"
                  >
                    <Input
                      placeholder="1"
                      type="number"
                      className="user-input-general-height"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  12Th*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Destination String
                      </span>
                    }
                    name="parent_Dst_String"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="some string"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Time String
                      </span>
                    }
                    name="parent_Time_String"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="some string"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* 13Th */}
              <Row gutter={[16, 15]}>
                <Col span={12}>
                  <Form.Item
                    name="parentQueComment"
                    // rules={[
                    //   { required: true, message: "Please Add your comments" },
                    // ]}
                    label={
                      <span className="create-user-form-label">Comments</span>
                    }
                  >
                    <TextArea
                      style={{ minWidth: "80%" }}
                      className="user-input-general-height"
                      placeholder="Your Comments"
                      // type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Final */}
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "50px",
                  }}
                >
                  <Form.Item>
                    <MikroMatikButton
                      backgroundColor={ButtonProps.background.STANDARD}
                      height={ButtonProps.height.STANDARD}
                      width={ButtonProps.width.STANDARD}
                      textColor={ButtonProps.color.STANDARD}
                      htmlType="submit"
                      onClick={() => {}}
                      text={
                        activeParentToEdit === null
                          ? "Create Profile"
                          : "Update Profile"
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </MikroMatikModal>
    </>
  );
};

export default CreateParentProfileModal;
