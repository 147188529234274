import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import CompanyStaffTable from "../../Items/Table/StaffTable";
const ViewStaffByCompany = () => {
  const navigate = useNavigate();
  const { intPkCompanyID } = useParams();
  const { openNotification, contextHolder } = useMultiNotification();
  const [StaffList, setStaffList] = useState<StaffProp[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetCompaniesApiProp>("auth/getCompanyStaff");
  useEffect(() => {
    postApiData({ intPkCompanyID: Number(intPkCompanyID) });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      setStaffList(data || []);
    }
  }, [apiResponse]);
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        {/* <Col span={6} offset={18}>
          <MikroMatikButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              navigate(`/create-package/${intPkCompanyID}`);
            }}
            text="Create Package"
          />
        </Col> */}
        <Col span={24}>
          <CompanyStaffTable staff={StaffList} isLoading={apiLoading} />
        </Col>
      </Row>
    </>
  );
};
export default ViewStaffByCompany;
