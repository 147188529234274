import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDate } from "../../../helpers/date";

import { MoreOutlined } from "@ant-design/icons";
import "./index.css";
const CompanyMikroTiksTable: React.FC<CompanyMikroTiksTable> = ({
  mikrotiks,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState<MikroTikSimple | null>(null);
  const packageOptionItems: MenuProps["items"] = [
    {
      key: "PACKAGES",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={ProfileIconBlue} /> */}
          View Packages
        </Typography>
      ),
    },
    {
      key: "MIKROTIKS",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={KeyIcon} /> */}
          View MikroTiks
        </Typography>
      ),
    },
  ];

  const columns: TableProps<MikroTikSimple>["columns"] = [
    {
      title: "Mikrotik Name",
      dataIndex: "mikrotikName",
      key: "mikrotikName",
    },
    {
      title: "NAS IP",
      dataIndex: "nasIpAddress",
      key: "nasIpAddress",
    },
    {
      title: "Username",
      dataIndex: "nasUsername",
      key: "nasUsername",
    },
    {
      title: "Password",
      dataIndex: "nasPassword",
      key: "nasPassword",
    },
    {
      title: "NAS PORT",
      dataIndex: "nasPort",
      key: "nasPort",
    },
    {
      title: "Created Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
  ];
  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    if (e.key === "PACKAGES") {
      navigate(`/view-packages-by-company/${activeRow.intPkCompanyID}`);
    } else if (e.key === "MIKROTIKS") {
      navigate(`/view-mikrotiks-by-company/${activeRow.intPkCompanyID}`);
    }
  };
  return (
    <div className="mikromatik-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="mikromatik-user-tables-heading">
                Company Mikrotiks
              </Typography>
            </div>
            <Table
              className="mikromatik-users-table striped-table"
              columns={columns}
              dataSource={mikrotiks}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default CompanyMikroTiksTable;
