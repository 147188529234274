import moment from "moment";
export const formatDate = (date: Date) => {
  return moment(date).format("D-MMMM-YYYY hh:mm A");
};

export const formatDateForJustMonthDayAndYear = (date: Date) => {
  return moment(date).format("DD-MM-YY");
};
export const convertRawTransworldDateToActualDate = (dateString: string) => {
  const actualDate = moment(dateString, "DD MMM YYYY HH:mm").toDate();
  return actualDate;
};
export const getDifferenceOfDaysBetweenCurrentTimeAndInputDate = (
  dateString: string
) => {
  const date2 = new Date(dateString);
  const date1 = new Date();
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();

  // Calculate the difference in milliseconds
  const differenceMs = date1Ms - date2Ms;

  // Convert the difference from milliseconds to days
  const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  return differenceDays;
};
