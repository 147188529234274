import React from "react";
import RoutesWrapper from "./routes";
import TitleProvider from "./context/TitleProvider";
import BalanceReloadProvider from "./context/BalanceReloadProvider";

function App() {
  return (
    <TitleProvider>
      <BalanceReloadProvider>
        <RoutesWrapper />
      </BalanceReloadProvider>
    </TitleProvider>
  );
}

export default App;
