import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDate } from "../../../helpers/date";

import { MoreOutlined } from "@ant-design/icons";
import "./index.css";
const { Search } = Input;
const CompaniesTable: React.FC<CompaniesTableProp> = ({
  companies,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState<CompanyProp | null>(null);
  const companyOptionItems: MenuProps["items"] = [
    {
      key: "STAFF_LIST",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={ProfileIconBlue} /> */}
          View Staff
        </Typography>
      ),
    },
    {
      key: "PACKAGES",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={ProfileIconBlue} /> */}
          View Packages
        </Typography>
      ),
    },
    {
      key: "MIKROTIKS",
      label: (
        <Typography className="profile-drop-down-item">
          {/* <img src={KeyIcon} /> */}
          View MikroTiks
        </Typography>
      ),
    },
  ];
  const columns: TableProps<CompanyProp>["columns"] = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Company Type",
      dataIndex: "companyType",
      key: "companyType",
    },
    {
      title: "Created Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: companyOptionItems,

            onClick: handleMenuClick,
          }}
          placement="bottomLeft"
        >
          <MoreOutlined
            onMouseOver={(e) => {
              e.preventDefault();
              setActiveRow(row);
            }}
          />
        </Dropdown>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    if (e.key === "STAFF_LIST") {
      navigate(`/view-staff-by-company/${activeRow.intPkCompanyID}`);
    }
    if (e.key === "PACKAGES") {
      navigate(`/view-packages-by-company/${activeRow.intPkCompanyID}`);
    } else if (e.key === "MIKROTIKS") {
      navigate(`/view-mikrotiks-by-company/${activeRow.intPkCompanyID}`);
    }
  };
  return (
    <div className="mikromatik-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="mikromatik-user-tables-heading">
                All Companies
              </Typography>
            </div>
            <Table
              className="mikromatik-users-table striped-table"
              columns={columns}
              dataSource={companies}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default CompaniesTable;
