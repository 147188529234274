import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
// import type { MenuProps } from "antd";

import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../../Loaders/TailSpinLoader";
import { formatDate } from "../../../../helpers/date";

// import { MoreOutlined } from "@ant-design/icons";
import "./../index.css";
const AdminMikroTiksTable: React.FC<AdminMikroTiksTable> = ({
  mikrotiks,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState<MikroTikSimple | null>(null);

  const columns: TableProps<MikrotikMini>["columns"] = [
    {
      title: "Mikrotik Name",
      dataIndex: "mikrotikName",
      key: "mikrotikName",
    },
    {
      title: "NAS IP",
      dataIndex: "nasIpAddress",
      key: "nasIpAddress",
    },

    {
      title: "Status",
      render: (row) => <span>{row.enable === 1 ? "Enable" : "Disable"}</span>,
    },

    {
      title: "Created Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
  ];
  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    if (e.key === "PACKAGES") {
      navigate(`/view-packages-by-company/${activeRow.intPkCompanyID}`);
    } else if (e.key === "MIKROTIKS") {
      navigate(`/view-mikrotiks-by-company/${activeRow.intPkCompanyID}`);
    }
  };
  return (
    <div className="mikromatik-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="mikromatik-user-tables-heading">
                My Mikrotiks
              </Typography>
            </div>
            <Table
              className="mikromatik-users-table striped-table"
              columns={columns}
              dataSource={mikrotiks}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default AdminMikroTiksTable;
