import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./../auth/styles/Login.css";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import MikroMatikButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
const LoginComponentToDeleteAccount = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<LoginType>("auth/login");
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { email, password } = values;
    const dataToSend: LoginType = {
      email: email,
      password: password,
    };
    postLoginData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {};
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      openNotification(`error`, "Login Failed", loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      const responseData: any = loginResponseData.responseData;
      const { authToken, user } = responseData;
      localStorage.setItem("authTokenToDeleteAccount", authToken || "");
      window.location.href = "/delete-account";
    }
  }, [loginResponseData]);
  return (
    <>
      {contextHolder}
      <div className="login-page-background">
        <div className="login-container">
          <Row gutter={[16, 1]} style={{ height: "100%" }}>
            <Col span={24}>
              <div className="logo-login-form">MikroMatik</div>

              <Form
                className="login-form"
                name="emailForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                requiredMark={false}
              >
                <Form.Item
                  label={<span className="create-user-form-label">Email</span>}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Email",
                    },
                    {
                      type: "email",
                      message: "Not a valid email",
                    },
                  ]}
                  style={{ marginBottom: "8px" }}
                >
                  <Input
                    style={{ height: "60px" }}
                    placeholder="Enter your Email"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span className="create-user-form-label">Password</span>
                  }
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Password",
                    },
                    {
                      min: 6,
                      message: "Minimum Characters for password are 6",
                    },
                  ]}
                  style={{ marginBottom: "8px" }}
                >
                  <Input.Password
                    style={{ height: "60px" }}
                    className="login-form-input-item"
                    placeholder="Enter your Password"
                  />
                </Form.Item>
                {/* <div className="remember-me-forgot-password-container">
                  <div className="forgot-password-container">
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/forgot-password");
                      }}
                      className="forgot-password-label"
                    >
                      Forgot Password
                    </span>
                  </div>
                </div> */}
                <Row>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={24}
                  >
                    <Form.Item>
                      <MikroMatikButton
                        htmlType="submit"
                        backgroundColor={ButtonProps.background.STANDARD}
                        height={ButtonProps.height.STANDARD}
                        width={ButtonProps.width.STANDARD}
                        textColor={ButtonProps.color.STANDARD}
                        onClick={() => {}}
                        text="Login"
                        disabled={loginLoading}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default LoginComponentToDeleteAccount;
