import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import { getUserType } from "../helpers/userDetails";
import DashboardComponent from "../components/dashboard/Dashboard";
import ViewMyMikrotiks from "../components/Admin/Views/ViewMyMikrotiks";

const HomePage = () => {
  const { setTitle } = useContext(TitleContext);
  const role = getUserType();

  useEffect(() => {
    setTitle("Dashboard");
  }, [setTitle]);
  const getActiveComponent = () => {
    switch (role) {
      case "SUPER_ADMIN":
        return <DashboardComponent />;
      case "ADMIN":
        return <ViewMyMikrotiks />;
    }
  };
  return <>{getActiveComponent()}</>;
};
export default HomePage;
