import React, { useState } from "react";
import MikroMatikModal from "../Items/Modal/Index";

import "./styles/index.css";

import useMultiNotification from "../../hooks/useNotification";
import { Col, Form, Input, Row, Select } from "antd";
import MikroMatikButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import MikroMatikSelect from "../Items/Select/Index";
import { getNumberOrFalse, getStringOrFalse } from "../../helpers/strings";
const { TextArea } = Input;

const initialValues = {
  child_MaxLimit_UploadInt: 0,
  child_MaxLimit_DownloadInt: 0,
  child_LimitAt_UploadInt: 0,
  child_LimitAt_DownloadInt: 0,
  child_BucketSize_UploadInt: 0,
  child_BucketSize_DownloadInt: 0,
  child_BurstLimit_UploadInt: 0,
  child_BurstLimit_DownloadInt: 0,
  child_BurstThreshold_UploadInt: 0,
  child_BurstThreshold_DownloadInt: 0,
  child_BurstTimeSeconds_UploadInt: 0,
  child_BurstTimeSeconds_DownloadInt: 0,
};
const CreateChildProfileModal: React.FC<CreateChildProfileModalProps> = ({
  show,
  parentId,
  activeChildToEdit,
  packetMarks,
  handleClose,
  handleAddNewChild,
  handleUpdateChild,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (values: any) => {
    const {
      childQueComment,
      childQueProfileDisplayName,
      child_BucketSize_DownloadInt,
      child_BucketSize_UploadInt,
      child_BurstLimit_AbbrvStr,
      child_BurstLimit_DownloadInt,
      child_BurstLimit_UploadInt,
      child_BurstThreshold_AbbrvStr,
      child_BurstThreshold_DownloadInt,
      child_BurstThreshold_UploadInt,
      child_BurstTimeSeconds_DownloadInt,
      child_BurstTimeSeconds_UploadInt,
      child_Dst_String,
      child_LimitAt_AbbrvStr,
      child_LimitAt_DownloadInt,
      child_LimitAt_UploadInt,
      child_MaxLimit_AbbrvStr,
      child_MaxLimit_DownloadInt,
      child_MaxLimit_UploadInt,
      child_Priority_DownloadInt,
      child_Priority_UploadInt,
      child_QueueType_DownloadType,
      child_QueueType_UploadType,
      child_Time_String,
      child_PacketMarkStrs,
      child_PacketMarksArray,
    } = values;
    const Obj: ChildPolicyProp = {
      child_PacketMarksArray,
      parentId: parentId,
      id: activeChildToEdit !== null ? activeChildToEdit.id : Math.random(),
      child_PacketMarkStrs:
        child_PacketMarksArray.length < 1
          ? false
          : child_PacketMarksArray.join(","),
      child_Time_String: getStringOrFalse(child_Time_String),
      childQueComment: getStringOrFalse(childQueComment),
      child_QueueType_DownloadType: getStringOrFalse(
        child_QueueType_DownloadType
      ),
      child_QueueType_UploadType: getStringOrFalse(child_QueueType_UploadType),
      childQueProfileDisplayName,
      child_BucketSize_DownloadInt: getStringOrFalse(
        child_BucketSize_DownloadInt
      ),
      child_MaxLimit_AbbrvStr: getStringOrFalse(child_MaxLimit_AbbrvStr),
      child_BucketSize_UploadInt: getStringOrFalse(child_BucketSize_UploadInt),
      child_BurstLimit_AbbrvStr: getStringOrFalse(child_BurstLimit_AbbrvStr),
      child_Dst_String: getStringOrFalse(child_Dst_String),
      child_LimitAt_AbbrvStr: getStringOrFalse(child_LimitAt_AbbrvStr),
      child_BurstLimit_DownloadInt: getNumberOrFalse(
        child_BurstLimit_DownloadInt
      ),
      child_BurstLimit_UploadInt: getNumberOrFalse(child_BurstLimit_UploadInt),
      child_BurstThreshold_AbbrvStr: getStringOrFalse(
        child_BurstThreshold_AbbrvStr
      ),
      child_BurstThreshold_DownloadInt: getNumberOrFalse(
        child_BurstThreshold_DownloadInt
      ),
      child_BurstThreshold_UploadInt: getNumberOrFalse(
        child_BurstThreshold_UploadInt
      ),
      child_BurstTimeSeconds_DownloadInt: getNumberOrFalse(
        child_BurstTimeSeconds_DownloadInt
      ),
      child_BurstTimeSeconds_UploadInt: getNumberOrFalse(
        child_BurstTimeSeconds_UploadInt
      ),
      child_LimitAt_DownloadInt: getNumberOrFalse(child_LimitAt_DownloadInt),
      child_LimitAt_UploadInt: getNumberOrFalse(child_LimitAt_UploadInt),
      child_MaxLimit_DownloadInt: getNumberOrFalse(child_MaxLimit_DownloadInt),
      child_MaxLimit_UploadInt: getNumberOrFalse(child_MaxLimit_UploadInt),
      child_Priority_DownloadInt: getNumberOrFalse(child_Priority_DownloadInt),
      child_Priority_UploadInt: getNumberOrFalse(child_Priority_UploadInt),
    };
    if (activeChildToEdit === null) {
      handleAddNewChild(Obj);
      handleClose(false);
    } else {
      handleUpdateChild(Obj);
      handleClose(false);
    }
  };

  return (
    <>
      <MikroMatikModal
        title={
          activeChildToEdit === null
            ? "Create Child Profile"
            : "Update Child Profile"
        }
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        minWidthPercentage="60%"
        open={show}
        onCancel={() => {
          // if (apiLoading) {
          //   return;
          // }
          handleClose(false);
        }}
      >
        {contextHolder}
        <Row>
          <Col span={20} offset={2}>
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="createChildProfileOfPolicyForm"
              initialValues={
                activeChildToEdit === null ? initialValues : activeChildToEdit
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Profile Display Name
                      </span>
                    }
                    name="childQueProfileDisplayName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Name",
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Packet Marks
                      </span>
                    }
                    name="child_PacketMarksArray"
                  >
                    <Select
                      // value={selectedItems}
                      // onChange={setSelectedItems}
                      size="large"
                      mode="multiple"
                      // value={selectedItems}
                      // onChange={setSelectedItems}
                      style={{ width: "100%", minHeight: "45px" }}
                      options={packetMarks.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Second */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Upload Limit
                      </span>
                    }
                    name="child_MaxLimit_UploadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Download Limit
                      </span>
                    }
                    name="child_MaxLimit_DownloadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Third */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Upload Limit At
                      </span>
                    }
                    name="child_LimitAt_UploadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Download Limit At
                      </span>
                    }
                    name="child_LimitAt_DownloadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Fourth */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Limit Unit
                      </span>
                    }
                    name="child_MaxLimit_AbbrvStr"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "K", value: "K" },
                        { label: "M", value: "M" },
                        { label: "G", value: "G" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Max Limit At Unit
                      </span>
                    }
                    name="child_LimitAt_AbbrvStr"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "K", value: "K" },
                        { label: "M", value: "M" },
                        { label: "G", value: "G" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  Fifth*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Parent Priority Upload
                      </span>
                    }
                    name="child_Priority_UploadInt"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                        { label: "6", value: "6" },
                        { label: "7", value: "7" },
                        { label: "8", value: "8" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Parent Priority Download
                      </span>
                    }
                    name="child_Priority_DownloadInt"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "5", value: "5" },
                        { label: "6", value: "6" },
                        { label: "7", value: "7" },
                        { label: "8", value: "8" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  Sixth*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Bucket Upload Limit
                      </span>
                    }
                    name="child_BucketSize_UploadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Bucket Download Limit
                      </span>
                    }
                    name="child_BucketSize_DownloadInt"
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  Seventh*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Upload Burst Limit
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="child_BurstLimit_UploadInt"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Download Burst Limit
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="child_BurstLimit_DownloadInt"
                  >
                    <Input
                      placeholder="1"
                      type="number"
                      className="user-input-general-height"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  8th*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Upload Burst Threshold Limit
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="child_BurstThreshold_UploadInt"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Download Burst Threshold Limit
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="child_BurstThreshold_DownloadInt"
                  >
                    <Input
                      placeholder="1"
                      type="number"
                      className="user-input-general-height"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  9th*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Upload Queue Type
                      </span>
                    }
                    name="child_QueueType_UploadType"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="enter type"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Download Queue Type
                      </span>
                    }
                    name="child_QueueType_DownloadType"
                  >
                    <Input
                      placeholder="enter type"
                      className="user-input-general-height"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* 10Th */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">Burst Unit</span>
                    }
                    name="child_BurstLimit_AbbrvStr"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "K", value: "K" },
                        { label: "M", value: "M" },
                        { label: "G", value: "G" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Burst Threshold Unit
                      </span>
                    }
                    name="child_BurstThreshold_AbbrvStr"
                  >
                    <MikroMatikSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "K", value: "K" },
                        { label: "M", value: "M" },
                        { label: "G", value: "G" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  11Th*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Burst Download Time Seconds
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="child_BurstTimeSeconds_UploadInt"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="1"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Burst Download Time Seconds
                      </span>
                    }
                    rules={[
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid Limit")),
                      },
                    ]}
                    name="child_BurstTimeSeconds_DownloadInt"
                  >
                    <Input
                      placeholder="1"
                      type="number"
                      className="user-input-general-height"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/*  12Th*/}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Destination String
                      </span>
                    }
                    name="child_Dst_String"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="some string"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Time String
                      </span>
                    }
                    name="child_Time_String"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="some string"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* 13Th */}
              <Row gutter={[16, 15]}>
                <Col span={12}>
                  <Form.Item
                    name="childQueComment"
                    // rules={[
                    //   { required: true, message: "Please Add your comments" },
                    // ]}
                    label={
                      <span className="create-user-form-label">Comments</span>
                    }
                  >
                    <TextArea
                      style={{ minWidth: "80%" }}
                      className="user-input-general-height"
                      placeholder="Your Comments"
                      // type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Final */}
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "50px",
                  }}
                >
                  <Form.Item>
                    <MikroMatikButton
                      backgroundColor={ButtonProps.background.STANDARD}
                      height={ButtonProps.height.STANDARD}
                      width={ButtonProps.width.STANDARD}
                      textColor={ButtonProps.color.STANDARD}
                      htmlType="submit"
                      onClick={() => {}}
                      text={
                        activeChildToEdit === null
                          ? "Create Profile"
                          : "Update Profile"
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </MikroMatikModal>
    </>
  );
};

export default CreateChildProfileModal;
