import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CreatePolicyForPackage from "../components/Admin/Forms/CreatePolicy";

const CreatePolicyForPackagePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create Policy");
  }, [setTitle]);

  return (
    <>
      <CreatePolicyForPackage />
    </>
  );
};
export default CreatePolicyForPackagePage;
