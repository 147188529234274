import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import { getUserType } from "../helpers/userDetails";
import ViewPackagesByCompany from "../components/Company/Views/ViewPackagesByCompany";

const ViewPackagesByCompanyPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Company Packages");
  }, [setTitle]);

  return (
    <>
      <ViewPackagesByCompany />
    </>
  );
};
export default ViewPackagesByCompanyPage;
