import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CreateMikroTikForCompanyForm from "../components/Company/Forms/CreateMikroTikForCompany";

const CreateMikroTikForCompanyPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create Mikrotik");
  }, [setTitle]);

  return (
    <>
      <CreateMikroTikForCompanyForm />
    </>
  );
};
export default CreateMikroTikForCompanyPage;
