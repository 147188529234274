import React from "react";
import { Select } from "antd";
const { Option } = Select;

interface MikroMatikSelectProps {
  size?: "large" | "middle" | "small";
  height?: string;
  isStandardSelectedColor?: boolean; // Color for the selected option
  onChange?: (value: any) => void;
  options: Array<MikroMatikSelectOptions>;
  value?: string | number | null;
  showSearch?: boolean;
  disabled?: boolean;
  placeHolder?: string;
}

const MikroMatikSelect: React.FC<MikroMatikSelectProps> = ({
  size = "middle",
  height,
  onChange,
  options,
  value,
  isStandardSelectedColor = false,
  showSearch = false,
  disabled = false,
  placeHolder,
}) => {
  const selectStyle: React.CSSProperties = {
    width: "100%",
    ...(height && { height }), // Apply height if provided
  };

  return (
    <Select
      disabled={disabled}
      className={`${
        isStandardSelectedColor === true ? "standard-select-control" : ""
      }`}
      value={value}
      style={selectStyle}
      size={size}
      onChange={onChange}
      showSearch={showSearch}
      optionFilterProp="label"
      placeholder={placeHolder ? placeHolder : ""}
    >
      {options.map((option) => (
        <Option key={Math.random()} value={option.value} label={option.label}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};
export default MikroMatikSelect;
