import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import MikroMatikButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import MikroMatikSelect from "../../Items/Select/Index";
const CreateCompanyForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateCompanyApiProp>(
    "auth/createNewAdminWithCompany",
    true
  );
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { email, password, fullName, companyName } = values;
    const dataToSend: CreateCompanyApiProp = {
      email: email,
      password: password,
      fullName,
      companyType: "THIRD_PARTY",
      companyName,
    };
    postApiData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {};
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(`/`);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);

  return (
    <>
      {contextHolder}
      <Row style={{ width: "100%" }}>
        <Form
          name="createCompanyForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Company Name</span>
                }
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Company Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Test Company"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Admin Name</span>
                }
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Company Admin Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="John Doe"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Email</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please Email",
                  },
                  {
                    type: "email",
                    message: "Invalid Email Entered",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="johndoe@example.com"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Password",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="veryRandom132$password"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <MikroMatikButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Create Company "
                  disabled={apiLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreateCompanyForm;
