import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CreatePackageForCompanyForm from "../components/Company/Forms/CreatePackageForCompany";

const CreatePackageForCompanyPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create Package");
  }, [setTitle]);

  return (
    <>
      <CreatePackageForCompanyForm />
    </>
  );
};
export default CreatePackageForCompanyPage;
