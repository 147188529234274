import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewMikroTiksByCompany from "../components/Company/Views/ViewMikroTiksByCompany";

const ViewMikrotiksByCompanyPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Company Mikrotiks");
  }, [setTitle]);

  return (
    <>
      <ViewMikroTiksByCompany />
    </>
  );
};
export default ViewMikrotiksByCompanyPage;
